import { Button, ScrollView, StyleSheet, Text, View } from 'react-native';
import { NavigationHelpers, ParamListBase } from '@react-navigation/native';

type Props = {
    navigation: NavigationHelpers<ParamListBase>;
}

export default function MyProfile({ navigation }: Props) {
    return (
        <ScrollView>
            <Button title='Update Profile' onPress={() => navigation.navigate('update-profile')} />
            <Button title='Change Password' onPress={() => navigation.navigate('change-password')} />
            <Button title='Logout' onPress={() => navigation.navigate('logout')} />
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#ddd',
    }
});