import { useState } from 'react';
import { Button, Image, ScrollView, StyleSheet, Text, TextInput, View } from 'react-native';
import { NavigationHelpers, ParamListBase } from '@react-navigation/native';
import ServerApi from '../../lib/server-api';

type Props = {
    navigation: NavigationHelpers<ParamListBase>;
}

export default function Logout({ navigation }: Props) {
    const [showError, setShowError] = useState(false);

    const submit = async () => {
        setShowError(false);

        try {
            const response = await ServerApi.logout();
            if (response.error) {
                throw new Error(response.errorMessage);
            }
            console.log('Got response:', JSON.stringify(response));
            navigation.navigate('home');
        } catch (error) {
            console.error('Got an error: ', JSON.stringify(error));
            setShowError(true);
        }
    };

    return (
        <ScrollView style={styles.container}>
            <Text style={{fontSize: 20, marginTop: 15, textAlign: 'center' }}>To log out, please press the button below</Text>

            <Button title='Logout' onPress={submit}></Button>
            { showError ? <Text style={{fontSize: 20, color: '#F00' }}>Error logging out, please try again</Text> : '' }
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        paddingLeft: 5,
        paddingRight: 5,
    },
});