import { useState } from 'react';
import { Button, Image, ScrollView, StyleSheet, Text, TextInput, View } from 'react-native';
import { NavigationHelpers, ParamListBase } from '@react-navigation/native';
import ServerApi from '../../lib/server-api';

type Props = {
    navigation: NavigationHelpers<ParamListBase>;
}

export default function UpdateProfile({ navigation }: Props) {
    const [entityName, setEntityName] = useState('');
    const [entityAddress, setEntityAddress] = useState('');
    const [showError, setShowError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const loadUser = async () => {
        if (isLoaded) return;
        const user = await ServerApi.User.user();
        console.log('Loaded user: ', user);
        setIsLoaded(true);

        setEntityName(user.entityName);
        setEntityAddress(user.entityAddress);
    };

    const submit = async () => {
        setShowError(false);

        const payload = {
            entityName,
            entityAddress,
        };

        try {
            const response = await ServerApi.User.updateProfile(payload);
            if (response.error) {
                throw new Error(response.errorMessage);
            }
            console.log('Got response:', JSON.stringify(response));
            navigation.navigate('my-profile');
        } catch (error) {
            console.error('Got an error: ', JSON.stringify(error));
            setShowError(true);
        }
    };

    loadUser();

    return (
        <ScrollView style={styles.container}>
            <Text style={{fontSize: 20, marginTop: 15 }}>Entity / Company Name</Text>
            <TextInput 
                style={{height: 40}} 
                placeholder='Mike Smith, Smith Industries' 
                onChangeText={newText => setEntityName(newText)} 
                defaultValue={entityName} 
                />

            <Text style={{fontSize: 20}}>Entity / Company Address</Text>
            <TextInput 
                style={{height: 40}}
                keyboardType="default"
                multiline={true}
                placeholder='123 Main St' 
                onChangeText={newText => setEntityAddress(newText)} 
                defaultValue={entityAddress} 
                />

            { showError ? <Text style={{fontSize: 20, color: '#F00' }}>Invalid password</Text> : '' }

            <Button title='Update Profile' onPress={submit}></Button>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        paddingLeft: 5,
        paddingRight: 5,
    },
});