import { useState } from 'react';
import { Button, Image, Platform, Pressable, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import ServerApi from '../../lib/server-api';
import Signature, { SignatureViewRef } from "react-native-signature-canvas";
import Sign from '../../Sign';
import { NavigationHelpers, ParamListBase } from '@react-navigation/native';
import { ALERT_TYPE, Toast } from 'react-native-alert-notification';
// import { GoogleSignin, GoogleSigninButton, statusCodes } from '@react-native-google-signin/google-signin';
import * as AppleAuthentication from 'expo-apple-authentication';
import { useTailwind } from 'tailwind-rn/dist';
import { HorizontalRule } from '../helper/HorizontalRule';
import Icon from 'react-native-ionicons';

type UIError = {
    error: boolean;
    message: string;
}

type Props = {
    navigation: NavigationHelpers<ParamListBase>;
    createdEmail: string|null;
}
export default function Login({ navigation, createdEmail }: Props) {
    const [email, setEmail] = useState(createdEmail ? createdEmail : '');
    const [password, setPassword] = useState('');
    const [googleLoaded, setGoogleLoaded] = useState(false);
    const [isSigninInProgress, setIsSigninInProgress] = useState(false);
    const [rememberMe, setRememberMe] = useState(true);
    const [formError, setFormError] = useState<UIError>({ error: false, message: '' });
    const tailwind = useTailwind();

    const submit = async () => {
        const loginData = {
            email,
            password,
        };

        setFormError({ error: false, message: '' });

        try {
            const response = await ServerApi.login(loginData);
            if (response.error) {
                console.log('Got response: ', response);
                return setFormError({ error: true, message: response.errorMessage });
            }
            // If the user object has an entityName saved, that means they have made an NDA before, so we send them to My NDAs instead
            if (response && response.user && response.user.entityName) {
                navigation.navigate('logged-in');
            } else {
                navigation.navigate('logged-in', { screen: 'create-nda' });
            }
            console.log('Got response:', JSON.stringify(response));
            // navigation.navigate('logged-in');
        } catch (error: any) {
            console.error('Got an error: ', JSON.stringify(error));
            setFormError({ error: true, message: 'Invalid username/password' });
        }
    };

    // const configGoogle = async () => {
    //     if (googleLoaded) return;
    //     GoogleSignin.configure({
    //         iosClientId: '627204501383-3aj9smugov86er74c2sfcfscpc6np5fr.apps.googleusercontent.com',
    //         webClientId: '627204501383-gvc7l57hg49pi781053fbr922bqj5aqd.apps.googleusercontent.com',
    //     });
    //     setGoogleLoaded(true);
    // };

    // const launchGoogleSSO = async() => {
    //     console.log('Launching google SSO...');
    //     setIsSigninInProgress(true);
    //     try {
    //         await GoogleSignin.hasPlayServices();
    //         const userInfo = await GoogleSignin.signIn();
    //         console.log('WE GOT USER INFO!!! ', userInfo);
    //     } catch (error: any) {
    //         if (error.code === statusCodes.SIGN_IN_CANCELLED) {
    //             console.log('USER CANCELLED FLOW!');
    //         } else if (error.code === statusCodes.IN_PROGRESS) {
    //             console.log('Sign in is already in progress?! ');
    //         } else if (error.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE) {
    //             console.log('Play services are unavailable!!!');
    //         } else {
    //             console.log('Got some other error: ', error);
    //         }
    //     }
    //     setIsSigninInProgress(false);
    // };

    const appleSignIn = async () => {
        try {
            const credential = await AppleAuthentication.signInAsync({
              requestedScopes: [
                AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                AppleAuthentication.AppleAuthenticationScope.EMAIL,
              ],
            });
            console.log('Apple sign in successful! Response: ', credential);
            // signed in
          } catch (e: any) {
            if (e.code === 'ERR_REQUEST_CANCELED') {
              // handle that the user canceled the sign-in flow
              console.log('User cancelled the login!');
            } else {
              // handle other errors
              console.log('Got different error: ', e);
            }
          }
    }

    // configGoogle();

    return (
        <ScrollView style={styles.container}>
            {/* <View style={tailwind('bg-green-400 border-green-500 p-3 border-2 rounded-md my-3')}>
                <Text>Your account has been created, login below</Text>
            </View> */}

            <Text style={tailwind('text-3xl font-semibold my-5')}>Log into account</Text>

            <Text style={tailwind('text-lg font-medium mt-3')}>Email</Text>
            <TextInput 
                style={styles.styledInput} 
                placeholder='Enter your email address'
                autoComplete='email'
                keyboardType='email-address'
                onChangeText={newText => setEmail(newText)} 
                defaultValue={email}
                autoFocus={createdEmail ? false : true}
                />

            <Text style={tailwind('text-lg font-medium mt-5')}>Password</Text>
            <TextInput 
                style={styles.styledInput}
                keyboardType="default"
                placeholder='Your password' 
                onChangeText={newText => setPassword(newText)} 
                defaultValue={password} 
                />

            <View style={{ flexDirection: 'row', ...tailwind('mt-3') }}>
                <TouchableOpacity style={{ flexDirection: 'row', justifyContent: 'flex-start', width: '50%' }} onPress={() => setRememberMe(!rememberMe)}>
                    <View style={{ width: 35, ...tailwind('') }}>
                        { !rememberMe ? <Icon size={32} name='square-outline' style={{ ...tailwind('text-cyan-700')}} /> : null }
                        { rememberMe ? <Icon size={32} name='checkbox' style={{ ...tailwind('text-cyan-700')}} /> : null }
                    </View>
                    <View style={{ flex: 1, ...tailwind('') }}>
                        <Text style={tailwind('pt-1 pl-1 text-base font-semibold')}>Remember me</Text>
                    </View>
                </TouchableOpacity>
                <View style={{ flex: 1, ...tailwind('') }}>
                    <Text style={{ alignSelf: 'flex-end', ...tailwind('pt-1 pl-3 text-base font-light text-blue-600') }} onPress={() => navigation.navigate('forgot-password')}>Forgot Password?</Text>
                </View>
            </View>
            
            { formError.error ? <Text style={tailwind('text-base text-red-600 my-5')}>{ formError.message }</Text> : null }

            <HorizontalRule text='or continue with' />

            {/* <GoogleSigninButton
                size={GoogleSigninButton.Size.Wide}
                color={GoogleSigninButton.Color.Light}
                onPress={launchGoogleSSO}
                disabled={isSigninInProgress}
                /> */}

            { Platform.OS === 'ios' ? (
                <AppleAuthentication.AppleAuthenticationButton
                    buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
                    buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.WHITE_OUTLINE}
                    cornerRadius={5}
                    style={{ height: 60, width: '100%' }}
                    onPress={appleSignIn}
                />
            ) : null }


            <Pressable style={{ ...styles.dropShadow, ...tailwind('bg-cyan-700 text-white rounded-full w-full mt-20')}} onPress={submit}>
                <Text style={tailwind('text-center text-white font-semibold text-lg my-3')}>Login</Text>
            </Pressable>

        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        paddingLeft: 15,
        paddingRight: 15,
    },
    styledInput: {
        borderBottomColor: '#111',
        borderBottomWidth: 1,
        height: 40,
        fontSize: 18,
        
    },
    dropShadow: {
        shadowColor: '#000',
        shadowOpacity: 0.5,
        elevation: 2,
        shadowOffset: { width: 0, height: 3 },
    }
});