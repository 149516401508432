import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { useTailwind } from 'tailwind-rn/dist';

export default function WebResetPasswordSuccess() {
    const tailwind = useTailwind();

    return (
        <ScrollView style={styles.container}>

            <View style={styles.container}>
                <Text style={tailwind('text-3xl font-semibold my-5')}>Password updated!</Text>
                <Text style={tailwind('text-lg mb-10 mt-2')}>Your password has been successfully updated, please return to the SimpleNDA app to login.</Text>
            </View>
        
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        paddingLeft: 5,
        paddingRight: 5,
    },
});