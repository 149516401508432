import { NavigationHelpers, ParamListBase, useNavigation } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { ActivityIndicator, Animated, Button, FlatList, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Icon from 'react-native-ionicons';
import WebView from 'react-native-webview';
import ServerApi from './lib/server-api';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import { RectButton } from 'react-native-gesture-handler';
import { DateTime } from 'luxon';



type ItemData = {
    id: string;
    title: string;
    status: number;
    createdAt: string;
    receiverSignedAt: string;
};

type ItemProps = {
    item: ItemData;
    onPress: () => void;
    backgroundColor: string;
    textColor: string;
};

export interface ItemPropsApi extends ItemProps {
    title: string;
    issuingUser?: any;
    receivingUser?: any;
    ndaUserArchives?: NdaUserArchive[];
    id: string;
    createdAt: string;
    updatedAt: string;
    receivingEntityName: string,
    receivingEntityAddress: string,
    receiverAccountCreated: boolean,
    receiverSignedAt: string|null,
    accessToken?: string,
    status: number,
}

export interface NdaUserArchive {
    id: string;
    userId: number;
    ndaId: number;
}

type ItemProps2 = {

}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#ddd',
    },
    item: {
      backgroundColor: '#f9c2ff',
      paddingHorizontal: 15,
      paddingVertical: 10,
      marginVertical: 6,
    //   marginHorizontal: 16,
      borderRadius: 5,
      borderColor: '#dedede',
      borderWidth: 0,
    },
    title: {
      fontSize: 16,
      width: '80%',
      paddingTop: 5,
    },
    titleIcon1: {
    },
    itemContainer: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start' // if you want to fill rows left to right
    },
  });

const Item = ({item, onPress, backgroundColor, textColor}: ItemProps) => (
    <TouchableOpacity onPress={onPress} style={[styles.item, {backgroundColor}]}>

        <View style={styles.itemContainer}>
            <View style={{ width: '92%' }}>
                <Text style={[styles.title, {color: textColor}]}>{item.title}</Text>
                <View style={styles.itemContainer}>
                    <View style={{ width: '50%' }}>
                        <Text style={{ fontSize: 10, color: '#888', paddingTop: 6 }}>Type</Text>
                        <Text style={[{fontSize: 12, color: '#555'}]}>Basic NDA</Text>

                        <Text style={{ fontSize: 10, color: '#888', paddingTop: 6 }}>Created</Text>
                        <Text style={[{fontSize: 12, color: '#555'}]}>{ item.createdAt }</Text>
                    </View>
                    <View style={{ width: '50%' }}>
                        <Text style={{ fontSize: 10, color: '#888', paddingTop: 6 }}>Status</Text>
                        <View style={styles.itemContainer}>
                            <View style={{ width: '8%' }}>
                                <Icon name='radio-button-on' color={ item.status ? 'green' : 'yellow' } style={[styles.titleIcon1, { fontSize: 8, marginTop: 3 } ]}></Icon>
                            </View>
                            <View style={{ width: '92%' }}>
                                <Text style={[{fontSize: 12, color: '#555'}]}>{ item.status ? 'Completed' : 'Pending Signature' }</Text>
                            </View>
                        </View>

                        { item.status > 0 ? (
                            <View>
                                <Text style={{ fontSize: 10, color: '#888', paddingTop: 6 }}>Executed</Text>
                                <Text style={[{fontSize: 12, color: '#555'}]}>{ item.receiverSignedAt }</Text>
                            </View>
                        ) : null}
                        
                    </View>
                </View>
            </View>

            <View style={{ width: '8%' }}>
                <Icon name='arrow-dropright' color='#bbb' style={[styles.titleIcon1, { marginTop: 30 } ]}></Icon>
            </View>
        </View>
        
    </TouchableOpacity>
);

type Props = {
    navigation: NavigationHelpers<ParamListBase>;
    reload?: boolean;
}

export default function MyNDAs({ navigation, reload }: Props) {
    const [ndas, setNDAs] = useState<ItemPropsApi[]>([]);

    const [archivedNdas, setArchivedNdas] = useState<ItemPropsApi[]>([]);
    const [unarchivedNdas, setUnarchivedNdas] = useState<ItemPropsApi[]>([]);
    const [pendingNdas, setPendingNdas] = useState<ItemPropsApi[]>([]);
    const [completedNdas, setCompletedNdas] = useState<ItemPropsApi[]>([]);
    const [useSplitUi, setUseSplitUi] = useState<boolean>(false);

    const [loaded, setLoaded] = useState(false);
    const [selectedId, setSelectedId] = useState<string>();
    const [activeView, setActiveView] = useState('active');
    
    const load = async (forceReload: boolean) => {
        if (loaded && !forceReload) return;
        const ndas = await ServerApi.NDA.myNdas() as ItemPropsApi[];
        // console.log('Loaded NDAS: ', ndas);
        setLoaded(true);
        if (!ndas) {
            return setNDAs([]);
        }
        const convertedNdas = ndas.map((n: ItemPropsApi) => {
            if (!n) return n;
            // n.title = `${n.issuingUser.entityName} > ${n.receivingEntityName}`;
            n.title = `${n.receivingEntityName}`;
            n.createdAt = DateTime.fromISO(n.createdAt).toLocaleString(DateTime.DATE_FULL).toString();
            if (n.receiverSignedAt) {
                n.receiverSignedAt = DateTime.fromISO(n.receiverSignedAt).toLocaleString(DateTime.DATE_FULL).toString();
            }

            return n;
        });

        // Filter out the archived, pending and completed
        console.log('Looping through ndas...');
        convertedNdas.map(n => {
            if (n.ndaUserArchives && n.ndaUserArchives.length) {
                console.log('NDA HAS ARCHIVE???? ', n.ndaUserArchives);
            } else {
                console.log(' NDA DOES NOT HAVE ARCHIVE ');
            }
        });
        const archived = convertedNdas.filter(n => n.ndaUserArchives && n.ndaUserArchives.length);
        console.log('SET ARCHIVED ONES TO: ', archived);
        const unarchived = convertedNdas.filter(n => !n.ndaUserArchives?.length);
        const pending = unarchived.filter(n => n.status === 0);
        const completed = unarchived.filter(n => n.status === 1);

        // If both categories have length, we show split ui, otherwise we just show normal ui
        const showSplitUI = pending.length && completed.length ? true : false;

        // Set all states
        setUseSplitUi(showSplitUI);
        setArchivedNdas(archived);
        setUnarchivedNdas(unarchived);
        setPendingNdas(pending);
        setCompletedNdas(completed);

        console.log('Set the NDAs to: ', convertedNdas);
        setNDAs(convertedNdas);
    };

    const itemClicked = (item: any) => {
        console.log('item clicked', item);
        navigation.navigate('NDA Details', { id: item.id });
    }
    
    const renderItem = ({item}: {item: ItemData}) => {
        const backgroundColor = item.id === selectedId ? '#6e3b6e' : '#f9c2ff';
        const color = item.id === selectedId ? 'white' : 'black';
    };

    const archiveItem = async (nda: any, archive: boolean) => {
        console.log('Archiving ', nda);
        const result = await ServerApi.NDA.archive(nda.id, archive);
        console.log('Got result from archive: ', result);
        // Then reload
        load(true);
    };

    const renderArchiveAction = (nda: any, archive: boolean) => {
        return (progress: any, dragX: any) => {
            const trans = dragX.interpolate({
                inputRange: [0, 50, 100, 101],
                outputRange: [-20, 0, 0, 1],
            });
            return (
                <RectButton style={{ width: 50, height: '100%', marginVertical: 5, paddingTop: 40 }} onPress={() => archiveItem(nda, archive)}>
                    <Animated.Text
                    style={[
                        { textAlign: 'center' },
                        { transform: [{ translateX: trans }] },
                    ]}>
                        <Icon name='archive' color='red' style={[styles.titleIcon1, { paddingTop: 30} ]}></Icon>
                    </Animated.Text>
                </RectButton>
            );
        };
    };

    useEffect(() => {
        /* @ts-ignore */
        const unsub = navigation.addListener('focus', (data, data2) => {
            console.log('Focused the myNDAs tab, any data?: ', data);
            console.log('is there anything else??: ', data2);
            load(true);
        });

        return unsub;
    }, [navigation]);

    load(false);

    if (reload) {
        load(true);
    }

    return (
        <View>
            <ScrollView style={{ height: '100%' }}>
                <View style={[styles.itemContainer, { paddingHorizontal: 15, paddingTop: 10 }]}>
                    <View style={{ width: '50%' }}>
                        <Text onPress={() => setActiveView('active')} style={{ fontWeight: activeView === 'active' ? 'bold' : 'normal'}}>My NDAs ({ unarchivedNdas.length })</Text>
                    </View>
                    <View style={{ width: '50%' }}>
                        <Text onPress={() => setActiveView('archived')} style={{ fontWeight: activeView === 'archived' ? 'bold' : 'normal'}}>Archived ({ archivedNdas.length })</Text>
                    </View>
                </View>

                { activeView === 'active' ? (
                    <View>
                        { useSplitUi ? (
                            <View>
                                <Text style={{ paddingHorizontal: 15, fontSize: 13, paddingTop: 15, paddingBottom: 5 }}>Pending</Text>
                                <View style={{ paddingHorizontal: 15 }}>
                                    { pendingNdas.map(nda => (
                                    <Swipeable key={nda.id} renderLeftActions={renderArchiveAction(nda, true)}>
                                        <Item key={nda.id} item={nda} backgroundColor={"#fff"} textColor="#000" onPress={() => itemClicked(nda)} />
                                    </Swipeable>
                                    )) }
                                </View>

                                <Text style={{ paddingHorizontal: 15, fontSize: 13, paddingTop: 15, paddingBottom: 5 }}>Completed</Text>
                                <View style={{ paddingHorizontal: 15 }}>
                                    { completedNdas.map(nda => (
                                    <Swipeable key={nda.id} renderLeftActions={renderArchiveAction(nda, true)}>
                                        <Item key={nda.id} item={nda} backgroundColor={"#fff"} textColor="#000" onPress={() => itemClicked(nda)} />
                                    </Swipeable>
                                    )) }
                                </View>
                            </View>
                        ) : (
                            <View style={{ paddingHorizontal: 15 }}>
                                { unarchivedNdas.map(nda => (
                                <Swipeable key={nda.id} renderLeftActions={renderArchiveAction(nda, true)}>
                                    <Item key={nda.id} item={nda} backgroundColor={"#fff"} textColor="#000" onPress={() => itemClicked(nda)} />
                                </Swipeable>
                                )) }
                            </View>
                        ) }
                    </View>
                ) : null }

                { activeView === 'archived' ? (
                    <View style={{ paddingHorizontal: 15 }}>
                        { archivedNdas.map(nda => (
                        <Swipeable key={nda.id} renderLeftActions={renderArchiveAction(nda, false)}>
                            <Item key={nda.id} item={nda} backgroundColor={"#fff"} textColor="#000" onPress={() => itemClicked(nda)} />
                        </Swipeable>
                        )) }
                    </View>
                ) : null }

                {/* <View style={{ paddingHorizontal: 15 }}>
                    { ndas.map(nda => (
                    <Swipeable renderLeftActions={renderArchiveAction(nda, true)}>
                        <Item key={nda.id} item={nda} backgroundColor={"#fff"} textColor="#000" onPress={() => itemClicked(nda)} />
                    </Swipeable>
                    )) }
                </View>

                <View>
                    <Text style={{ paddingHorizontal: 15, fontSize: 12, paddingTop: 10 }}>Pending</Text>
                </View> */}
                
                {/* { ndas.map(nda => <Item key={nda.id} item={nda} backgroundColor={"#fff"} textColor="#000" onPress={() => itemClicked(nda)} />) } */}
                <Button title='Refresh' onPress={() => load(true)}></Button>
            </ScrollView>
            
        </View>
    );
}
