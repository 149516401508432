import { createRef, useRef, useState } from 'react';
import { Button, Image, ScrollView, StyleSheet, Text, TextInput, View } from 'react-native';
import { Link, NavigationHelpers, ParamListBase, RouteProp } from '@react-navigation/native';
import ServerApi from '../../lib/server-api';
import { NDADetailsApi } from '../../NDADetails';
import SignaturePad from 'react-signature-pad-wrapper';
import * as FileSystem from 'expo-file-system';
import { HorizontalRule } from '../helper/HorizontalRule';


type Props = {
    navigation: NavigationHelpers<ParamListBase>;
    route: RouteProp<ParamListBase>;
}

export default function GuestView({ navigation, route }: Props) {
    const [nda, setNda] = useState<NDADetailsApi|null>(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);

    const { id, token } = route.params as any; //:(
    console.log('GUEST VIEW, GOT ID + TOKEN: ', id, token);
    const loadNda = async () => {
        if (isLoaded) return;
        const nda = await ServerApi.Guest.retrieveNda(id, token);
        setIsLoaded(true);
        console.log('Loaded NDA: ', nda);
        setNda(nda);
        if (!nda.receiverAccountCreated) {
            setEmail(nda.receivingEntityAddress);
        }
    };

    loadNda();

    const submit = async () => {
        setShowError(false);

        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match');
            return setShowError(true);
        }

        const payload = {
            email,
            password,
            id,
            token,
        };

        try {
            const response = await ServerApi.Guest.createAccount(payload);
            if (response.error) {
                throw new Error(response.errorMessage);
            }
            console.log('Got response:', JSON.stringify(response));
            navigation.navigate('guest-thankyou');
        } catch (error) {
            console.log('Got an error: ', error);
            setShowError(true);
            setErrorMessage(error+"");
        }
        
    };

    const download = async (url: string) => {
        const saveFileName = FileSystem.documentDirectory + "NDA_Contract.pdf";
        console.log('Downloading...', url);
        const foo = await FileSystem.downloadAsync(url, saveFileName);
        console.log('Sent the foo ', foo);
    }

    return (
        <ScrollView style={styles.container}>
            { nda && (<View style={styles.container}>
                <View style={{ marginHorizontal: 10 }}>
                    <Text style={{ fontWeight: 'bold', fontSize: 18, marginTop: 10, marginBottom: 10 }}>Your NDA is complete, view and download it below</Text>
                </View>
                <iframe src={`${nda.ndaFiles[0].fileName}#toolbar=1`} height={400} width='100%' style={{ margin: '0 auto' }} />
                
                {/* <Button title='Download NDA' onPress={() => download(`${apiDocumentBase}${nda.ndaFiles[0].fileName}`)} /> */}
                <a href={`${nda.ndaFiles[0].fileName}`} style={{ paddingTop: 10, display: 'block' }} target="_blank">Download NDA</a>
                {/* <Link target='_blank' to={`${apiDocumentBase}${nda.ndaFiles[0].fileName}`}>
                    <Button title='Download NDA' onPress={() => download(`${apiDocumentBase}${nda.ndaFiles[0].fileName}`)} />
                </Link> */}
                { !nda.receiverAccountCreated && (
                    <View style={styles.container}>
                        <HorizontalRule text="Wasn't that easy?" />
                        <Text style={{ marginTop: 0, fontSize: 24, lineHeight: 34, textAlign: 'center'}}><a href="https://simplemnda.com" target="_blank" style={{ color: '#000'}}>SimpleNDA</a> is an iOS/Android app that lets you send NDA contracts for free!</Text>

                        <Text style={{ marginTop: 20, fontSize: 18, lineHeight: 24, textAlign: 'center' }}>To get permanent download access to this NDA, enter a password below to create a free SimpleNDA account.</Text>
                        <Text style={{fontSize: 18, marginTop: 15, marginBottom: 5 }}>Email Address</Text>
                        <TextInput 
                            style={{height: 40, borderWidth: 1, borderRadius: 4, paddingHorizontal: 5, paddingVertical: 2}} 
                            placeholder='you@domain.com' 
                            onChangeText={newText => setEmail(newText)} 
                            defaultValue={email} 
                            />

                        <Text style={{fontSize: 18, marginTop: 15, marginBottom: 5 }}>New Password</Text>
                        <TextInput 
                            style={{height: 40, borderWidth: 1, borderRadius: 4, paddingHorizontal: 5, paddingVertical: 2}}
                            keyboardType="default"
                            placeholder='Your password' 
                            onChangeText={newText => setPassword(newText)} 
                            defaultValue={password} 
                            />

                        <Text style={{fontSize: 18, marginTop: 15, marginBottom: 5 }}>Confirm Password</Text>
                        <TextInput 
                            style={{height: 40, marginBottom: 10, borderWidth: 1, borderRadius: 4, paddingHorizontal: 5, paddingVertical: 2}}
                            keyboardType="default"
                            placeholder='Confirm password' 
                            onChangeText={newText => setConfirmPassword(newText)} 
                            defaultValue={confirmPassword} 
                            />

                        { showError && <Text style={{fontSize: 20, color: '#F00' }}>{ errorMessage }</Text> }

                        <Button title='Create Account' onPress={submit}></Button>
                    </View>
                )}
                

            </View>)}
            


        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        paddingLeft: 5,
        paddingRight: 5,
    },
});