import { useState } from 'react';
import { NavigationHelpers, ParamListBase, useNavigation } from '@react-navigation/native';

import { Alert, Button, Image, ScrollView, StyleSheet, Text, TextInput, View } from 'react-native';
import ServerApi from '../../lib/server-api';
import Signature, { SignatureViewRef } from "react-native-signature-canvas";
import Sign from '../../Sign';
import { useTailwind } from 'tailwind-rn/dist';
import { themeStyles } from '../../themes/default';

type Props = {
    navigation: NavigationHelpers<ParamListBase>;
}
export default function CreateNDA({ navigation }: Props) {
    const [entityName, setEntityName] = useState('');
    const [receivingEntityName, setReceivingEntityName] = useState('');
    const [receivingEntityAddress, setReceivingEntityAddress] = useState('');
    const [ndaTopic, setNdaTopic] = useState('');
    const [signature, setSignature] = useState<any>(null);
    const [scrollEnabled, setScrollEnabled] = useState(true);
    const [loadedDefaults, setLoadedDefaults] = useState(false);
    const tailwind = useTailwind();

    const [errorMessage, setErrorMessage] = useState('');

    const loadDefaults = async () => {
        if (loadedDefaults) return;
        const user = await ServerApi.User.user();
        console.log('Loaded  user information, setting defaults: ', user);
        if (user) {
            setLoadedDefaults(true);
            setEntityName(user.entityName);
        }

    };

    loadDefaults();

    const handleOK = (signature: any) => {
        console.log(signature);
        setSignature(signature);
    };

    const handleEmpty = () => {
        console.log("Empty");
    };

    const prepareNDA = async () => {
        const ndaData = {
            entityName,
            receivingEntityName,
            receivingEntityAddress,
            signature,
        };

        if (!receivingEntityName) return setErrorMessage('You must provide a recipient name');
        if (!receivingEntityAddress) return setErrorMessage('You must provide a recipient phone/email');
        if (!signature) return setErrorMessage('You must confirm your signature');

        try {
            const res = await ServerApi.NDA.create(ndaData);
            console.log('Got response:', JSON.stringify(res));

            setReceivingEntityName('');
            setReceivingEntityAddress('');
            setSignature(null);

            navigation.navigate('my-ndas', { reload: true });
        } catch (error) {
            Alert.alert('Error', 'There was a problem with your request, please try again');
            // setErrorMessage('Error: ' + JSON.stringify(error));
            console.error('Got an error: ', JSON.stringify(error));
        }
        
    };

    const style = `.m-signature-pad--footer
        .button {
        background-color: red;
        color: #FFF;
        }`;

    return (
        
        <ScrollView style={styles.container} scrollEnabled={scrollEnabled}>
            { !loadedDefaults ? (<Text>Loading</Text>) : (
            <View style={{ marginHorizontal: 15 }}>
                <Text style={tailwind('text-3xl font-semibold mt-5')}>Create new agreement</Text>
                <Text style={tailwind('text-lg mt-4 text-gray-800')}>Confirm your personal information to be used in this agreement.</Text>

                <Text style={{ fontSize: 16, marginTop: 20}}>Your Name or Business Name</Text>
                <TextInput 
                    style={{height: 32, fontSize: 16 }} 
                    placeholder='Your Business Name' 
                    onChangeText={newText => setEntityName(newText)} 
                    defaultValue={entityName} 
                    />

                {/* <Text style={{ fontSize: 16, marginTop: 20}}>Your Address</Text>
                <TextInput 
                    style={{height: 32, fontSize: 16 }} 
                    placeholder='Your Business Address' 
                    onChangeText={newText => setEntityName(newText)} 
                    defaultValue={entityName} 
                    /> */}

                <Text style={{ fontSize: 16, marginTop: 15 }}>Recipient Name or Business Name</Text>
                <TextInput 
                    style={{height: 32, fontSize: 16 }} 
                    placeholder='Recipient Business Name' 
                    onChangeText={newText => setReceivingEntityName(newText)} 
                    defaultValue={receivingEntityName} 
                    />

                <Text style={{ fontSize: 16, marginTop: 15 }}>Recipient Email</Text>
                <TextInput 
                    style={{ height: 32, fontSize: 16 }}
                    placeholder='Recipient Email' 
                    onChangeText={newText => setReceivingEntityAddress(newText)} 
                    defaultValue={receivingEntityAddress} 
                    />

                <Text style={{ fontSize: 16, marginTop: 10 }}>Your Signature</Text>
                <Sign text='Sign Here' signature={signature} onOK={(img) => setSignature(img)} setScroll={(value) => setScrollEnabled(value)}  />

                {errorMessage ? (<Text style={{ color: 'red', borderRadius: 1, textAlign: 'center'}}>{errorMessage}</Text>) : ''}
                <Button title='Send NDA' onPress={prepareNDA}></Button>
            </View>
            ) }
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    ...themeStyles,
    container: {
        backgroundColor: '#fff',
    },
    preview: {
        width: 335,
        height: 114,
        backgroundColor: "#F8F8F8",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 15,
      },
      previewText: {
        color: "#FFF",
        fontSize: 14,
        height: 40,
        lineHeight: 40,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: "#69B2FF",
        width: 120,
        textAlign: "center",
        marginTop: 10,
      },
});