import { Text, View } from "react-native";
import { useTailwind } from "tailwind-rn/dist";


export const HorizontalRule = ({ text }: { text: string }) => {
    const tailwind = useTailwind();

    return (
        <View style={{flexDirection: 'row', alignItems: 'center', ...tailwind('my-5')}}>
            <View style={{flex: 1, height: 1, backgroundColor: 'black'}} />
            <View>
                <Text style={{textAlign: 'center', ...tailwind('text-lg px-5')}}>{text}</Text>
            </View>
            <View style={{flex: 1, height: 1, backgroundColor: 'black'}} />
        </View>
    );
}