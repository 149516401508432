import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import Homepage from "./components/Homepage";
import CreateNDA from "./components/nda/CreateNDA";
import MyProfile from "./MyProfile";
import Icon from "react-native-ionicons";
import MyNDAs from "./MyNDAs";
import { createStackNavigator } from "@react-navigation/stack";
import NDADetails from "./NDADetails";
import { RootStackParamList } from "./App";
import ChangePassword from "./components/profile/ChangePassword";
import UpdateProfile from "./components/profile/UpdateProfile";
import Logout from "./components/profile/Logout";
import { LogoHeader } from "./LogoHeader";

const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

const MyNDARoutes = () => {
    return (
        <Stack.Navigator initialRouteName="my-ndas" screenOptions={{ headerShown: true }}>
            <Stack.Screen name="my-ndas" component={MyNDAs} options={{headerLeft: () => null, title: 'My NDAs', headerTitle: (props) => <LogoHeader {...props} /> }} />
            <Stack.Screen name="NDA Details" component={NDADetails} options={{ headerTitle: (props) => <LogoHeader {...props} /> }} />
        </Stack.Navigator>
    );
}

const MyProfileRoutes = () => {
    return (
        <Stack.Navigator initialRouteName="my-profile" screenOptions={{ headerShown: true }}>
            <Stack.Screen name="my-profile" component={MyProfile} options={{headerLeft: () => null, title: 'My Profile', headerTitle: (props) => <LogoHeader {...props} /> }} />
            <Stack.Screen name="update-profile" component={UpdateProfile} options={{ title: 'Update Profile', headerTitle: (props) => <LogoHeader {...props} /> }} />
            <Stack.Screen name="change-password" component={ChangePassword} options={{ title: 'Change Password', headerTitle: (props) => <LogoHeader {...props} /> }} />
            <Stack.Screen name="logout" component={Logout} options={{ title: 'Log Out', headerLeft: () => null, headerTitle: (props) => <LogoHeader {...props} /> }} />
        </Stack.Navigator>
    );
}

export default function BottomNavigation() {
    return (
        <Tab.Navigator 
            screenOptions={({ route }) => ({
                // headerShown: false,
                tabBarIcon: ({ focused, color, size }) => {
                    let iconName = 'add';

                    if (route.name === 'My NDAs') {
                        iconName = 'document';
                    }
                    if (route.name === 'My Profile') {
                        iconName = 'person';
                    }

                    return <Icon name={iconName} />;
                }
            })}
            initialRouteName="My NDAs"
            >
            <Tab.Screen name="My NDAs" component={MyNDARoutes} options={{headerShown: false}}></Tab.Screen>
            <Tab.Screen name="create-nda" component={CreateNDA} options={{ title: 'New NDA', headerTitle: (props) => <LogoHeader {...props} /> }}></Tab.Screen>
            <Tab.Screen name="My Profile" component={MyProfileRoutes} options={{ headerShown: false }}></Tab.Screen>
        </Tab.Navigator>
    );
}