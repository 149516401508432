import { NavigationHelpers, ParamListBase } from '@react-navigation/native';
import { useState } from 'react';
import { Button, Image, Pressable, ScrollView, StyleSheet, Text, TextInput, View } from 'react-native';
import { useTailwind } from 'tailwind-rn/dist';
import ServerApi from '../../lib/server-api';
import { UIError } from '../../types/ui-types';

type Props = {
    navigation: NavigationHelpers<ParamListBase>;
}
export default function ForgotPassword({ navigation }: Props) {
    const [email, setEmail] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [formError, setFormError] = useState<UIError>({ error: false, message: '' });
    const tailwind = useTailwind();

    const submit = async () => {
        setShowSuccess(false);
        setFormError({ error: false, message: '' });

        if (!email || !email.length || email.indexOf('@') === -1 || email.indexOf('.') === -1) {
            return setFormError({ error: true, message: 'Invalid email address' });
        }

        try {
            const response = await ServerApi.Guest.forgotPassword({ email });
            console.log('Got response:', response);
            if (response && response.error) {
                return setFormError({ error: true, message: response.errorMessage });
            }

            setShowSuccess(true);
            // navigation.navigate('login');
        } catch (error) {
            setFormError({ error: true, message: 'An error occured, please try again' });
            console.error('Got an error: ', error);
        }
    };

    return (
        <ScrollView style={styles.container}>
            <Text style={tailwind('text-3xl font-semibold mt-5')}>Forgot password</Text>
            <Text style={tailwind('text-lg mb-10 mt-2')}>Enter your email address.  We will send you an authentication code for the next step.</Text>

            { showSuccess ? (
                <View style={tailwind('bg-green-400 border-green-500 p-3 border-2 rounded-md my-3')}>
                    <Text>An email has been sent to the matching account</Text>
                </View>
            ) : null }
            

            <Text style={tailwind('text-lg font-medium')}>Email</Text>
            <TextInput 
                style={styles.styledInput} 
                placeholder='Enter your email address'
                autoComplete='email'
                keyboardType='email-address'
                onChangeText={newText => setEmail(newText)} 
                defaultValue={email}
                autoFocus={true}
                />

            { formError.error ? <Text style={tailwind('text-base text-red-600 my-5')}>{ formError.message }</Text> : null }
            
            <Pressable style={tailwind('bg-cyan-700 text-white rounded-full w-full mt-20')} onPress={submit}>
                <Text style={tailwind('text-center text-white font-semibold text-lg my-3')}>Continue</Text>
            </Pressable>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        paddingLeft: 15,
        paddingRight: 15,
    },
    styledInput: {
        borderBottomColor: '#111',
        borderBottomWidth: 1,
        height: 40,
        fontSize: 18,
    }
});