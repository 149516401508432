import { NavigationHelpers, ParamListBase, useNavigation } from '@react-navigation/native';
import { Button, Image, Platform, Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import * as Linking from 'expo-linking';
import * as AppleAuthentication from 'expo-apple-authentication';
import {useTailwind} from 'tailwind-rn/dist';
import { useAssets } from 'expo-asset';
// import { GoogleSignin, GoogleSigninButton, statusCodes } from '@react-native-google-signin/google-signin';
import { useEffect, useState } from 'react';
import { HorizontalRule } from './helper/HorizontalRule';

type Props = {
    navigation: NavigationHelpers<ParamListBase>;
}

export default function Homepage({ navigation }: Props) {
    const tailwind = useTailwind();
    const [assets, error] = useAssets([require('../assets/HomepageNda.png')])
    const [isSigninInProgress, setIsSigninInProgress] = useState(false);
    const [googleLoaded, setGoogleLoaded] = useState(false);

    const detectLink = async () => {
        const url = await Linking.getInitialURL();
        const parsed = new URL(url+"");
        const pieces = parsed.pathname.split('/');

        // Reset Password
        if (pieces.length > 3 && pieces[1].toLowerCase() === 'reset-password') {
            const userId = pieces[2];
            const token = pieces[3];
            navigation.navigate('reset-password', { id: userId, token: token });
        }
        // Look for the signing page
        if (pieces.length > 3 && pieces[1].toLowerCase() === 'sign') {
            const ndaId = pieces[2];
            const token = pieces[3];
            navigation.navigate('guest-sign', { id: ndaId, token: token });
        }
        // Look for the viewing page
        if (pieces.length > 3 && pieces[1].toLowerCase() === 'view') {
            const ndaId = pieces[2];
            const token = pieces[3];
            navigation.navigate('guest-view', { id: ndaId, token: token });
        }
    }

    const appleSignIn = async () => {
        try {
            const credential = await AppleAuthentication.signInAsync({
              requestedScopes: [
                AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                AppleAuthentication.AppleAuthenticationScope.EMAIL,
              ],
            });
            console.log('Apple sign in successful! Response: ', credential);
            // signed in
          } catch (e: any) {
            if (e.code === 'ERR_REQUEST_CANCELED') {
              // handle that the user canceled the sign-in flow
              console.log('User cancelled the login!');
            } else {
              // handle other errors
              console.log('Got different error: ', e);
            }
          }
    }

    if (Platform.OS === 'web') {
        detectLink();
    }

    // useEffect(() => {
    //     GoogleSignin.configure({
    //         iosClientId: '627204501383-3aj9smugov86er74c2sfcfscpc6np5fr.apps.googleusercontent.com',
    //         webClientId: '627204501383-gvc7l57hg49pi781053fbr922bqj5aqd.apps.googleusercontent.com',
    //     });
    // }, []);

    // const launchGoogleSSO = async() => {
    //     console.log('Launching google SSO...');
    //     setIsSigninInProgress(true);
    //     try {
    //         await GoogleSignin.hasPlayServices();
    //         const userInfo = await GoogleSignin.signIn();
    //         console.log('WE GOT USER INFO!!! ', userInfo);
    //     } catch (error: any) {
    //         if (error.code === statusCodes.SIGN_IN_CANCELLED) {
    //             console.log('USER CANCELLED FLOW!');
    //         } else if (error.code === statusCodes.IN_PROGRESS) {
    //             console.log('Sign in is already in progress?! ');
    //         } else if (error.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE) {
    //             console.log('Play services are unavailable!!!');
    //         } else {
    //             console.log('Got some other error: ', error);
    //         }
    //     }
    //     setIsSigninInProgress(false);
    // };


    // const configGoogle = async () => {
    //     if (googleLoaded) return;
    //     GoogleSignin.configure({
    //         iosClientId: '627204501383-3aj9smugov86er74c2sfcfscpc6np5fr.apps.googleusercontent.com',
    //         webClientId: '627204501383-gvc7l57hg49pi781053fbr922bqj5aqd.apps.googleusercontent.com',
    //     });
    //     setGoogleLoaded(true);
    // };
    // configGoogle();

    return (
        <ScrollView style={styles.container}>
            <View style={{ justifyContent: 'center', alignItems: 'center', paddingLeft: 15, paddingRight: 15 }}>
                { assets ? (
                    <Image source={assets[0]} style={{ width: 225, height: 225, marginTop: 15, marginBottom: 25 }} />
                ) : null }

                <Text style={tailwind('text-4xl font-bold mb-10 mt-5')}>Get started</Text>

                {/* { Platform.OS !== 'web' ? (
                     <GoogleSigninButton
                        size={GoogleSigninButton.Size.Wide}
                        color={GoogleSigninButton.Color.Light}
                        onPress={launchGoogleSSO}
                        disabled={isSigninInProgress}
                        style={{ width: '100%', marginBottom: 15 }}
                     />
                ) : null } */}
               
                { Platform.OS === 'ios' ? (
                    <AppleAuthentication.AppleAuthenticationButton
                        buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
                        buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.WHITE_OUTLINE}
                        cornerRadius={5}
                        style={{ height: 60, width: '100%' }}
                        onPress={appleSignIn}
                    />
                ) : null }

                <HorizontalRule text='or' />

                <Pressable style={tailwind('bg-cyan-700 text-white rounded-lg m-2 w-full')} onPress={() => navigation.navigate('login') }>
                    <Text style={tailwind('text-center text-white font-bold text-lg my-2')}>Sign in with e-mail</Text>
                </Pressable>


                {/* <Button title='Sign in with e-mail' onPress={() => navigation.navigate('create-account') } /> */}
                <Text style={tailwind('text-lg')}>
                    Don't have an account? <Text style={tailwind('text-blue-600')} onPress={() => navigation.navigate('create-account')}>Sign up</Text>
                </Text>
            </View>
            {/* <Text style={styles.homepageText}>Create and manage an NDA in minutes</Text> */}
            {/* <Button title='Login' onPress={() => navigation.navigate('login') } />
            <Button title='Create Account' onPress={() => navigation.navigate('create-account') } /> */}

            

            
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
    },
    homepageText: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    }
});