import { useState } from 'react';
import { Button, Image, ScrollView, StyleSheet, Text, TextInput, View } from 'react-native';
import { NavigationHelpers, ParamListBase } from '@react-navigation/native';
import ServerApi from '../../lib/server-api';

type Props = {
    navigation: NavigationHelpers<ParamListBase>;
}

export default function ChangePassword({ navigation }: Props) {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showError, setShowError] = useState(false);

    const submit = async () => {
        setShowError(false);
        if (newPassword !== confirmPassword) {
            return setShowError(true);
        }

        const payload = {
            password,
            newPassword,
        };

        try {
            const response = await ServerApi.User.changePassword(payload);
            if (response.error) {
                throw new Error(response.errorMessage);
            }
            console.log('Got response:', JSON.stringify(response));
            navigation.navigate('my-profile');
        } catch (error) {
            console.error('Got an error: ', JSON.stringify(error));
            setShowError(true);
        }
        
    };

    return (
        <ScrollView style={styles.container}>
            <Text style={{fontSize: 20, marginTop: 15 }}>Current Password</Text>
            <TextInput 
                style={{height: 40}} 
                placeholder='you@domain.com' 
                onChangeText={newText => setPassword(newText)} 
                defaultValue={password} 
                />

            <Text style={{fontSize: 20}}>New Password</Text>
            <TextInput 
                style={{height: 40}}
                keyboardType="default"
                multiline={true}
                placeholder='Your password' 
                onChangeText={newText => setNewPassword(newText)} 
                defaultValue={newPassword} 
                />

            <Text style={{fontSize: 20}}>Confirm New Password</Text>
            <TextInput 
                style={{height: 40}}
                keyboardType="default"
                multiline={true}
                placeholder='Your password' 
                onChangeText={newText => setConfirmPassword(newText)} 
                defaultValue={confirmPassword} 
                />

            { showError ? <Text style={{fontSize: 20, color: '#F00' }}>Invalid password</Text> : '' }

            <Button title='Update Password' onPress={submit}></Button>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        paddingLeft: 5,
        paddingRight: 5,
    },
});