import { ScrollView, StyleSheet, Text } from 'react-native';
import { NavigationHelpers, ParamListBase, RouteProp } from '@react-navigation/native';


type Props = {
    navigation: NavigationHelpers<ParamListBase>;
    route: RouteProp<ParamListBase>;
}

export default function GuestThankYou({ navigation, route }: Props) {
    return (
        <ScrollView style={styles.container}>
            <Text style={{ fontSize: 18, marginVertical: 15, textAlign: 'center'}}>Your account has been created!</Text>
            <Text style={{ fontSize: 18, marginVertical: 15, textAlign: 'center' }}>
                To begin sending your own NDA's for free, please <a href="https://simplemnda.com" style={{ color: '#000'}}>download the SimpleNDA app on the App Store</a>
            </Text>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        paddingLeft: 5,
        paddingRight: 5,
    },
});