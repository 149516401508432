import { useState } from 'react';
import { ActivityIndicator, Button, Image, Platform, SafeAreaView, ScrollView, StyleSheet, Text, TextInput, View } from 'react-native';
import ServerApi from './lib/server-api';
import { ItemPropsApi } from './MyNDAs';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from './App';

import WebView from 'react-native-webview';
// import { WebView } from 'react-native-webview';
import * as FileSystem from 'expo-file-system';
import { shareAsync } from 'expo-sharing';

type Props = {
    route: RouteProp<RootStackParamList>;
}

type RouteProps = StackNavigationProp<RootStackParamList>

export type NDAFileApi = {
    id: number;
    ndaId: number;
    createdAt: string;
    updatedAt: string;
    fileName: string;
}

export interface NDADetailsApi extends ItemPropsApi {
    ndaFiles: NDAFileApi[];
}

export default function NDADetails({ route }: Props) {
    const [nda, setNDA] = useState<NDADetailsApi|null>(null);
    const [isLoaded, setLoaded] = useState(false);
    // const route2 = useRoute<RouteProps>();
    console.log(route.params);


    // Load from the api
    const load = async () => {
        if (!route.params || !route.params.id) return;
        if (isLoaded) return;
        const nda: NDADetailsApi = await ServerApi.NDA.details(route.params.id);
        setLoaded(true);
        console.log('Loaded nda: ', nda);
        setNDA(nda);
    };

    const downloadNda = async () => {
        console.log('Download the nda...');
        const fileName = `${nda?.ndaFiles[0].fileName}`;
        const result = await FileSystem.downloadAsync(fileName, FileSystem.documentDirectory + `NDA_Contract-${nda?.id}.pdf`);
        console.log('Result: ', result);
        save(result.uri);
    };
    const save = (url: string) => {
        console.log('Calling save on: ', url);
        shareAsync(url);
    }

    load();

    return (
        
        <ScrollView style={styles.container}>
            { !nda ? <Text>Loading NDA</Text> : (
                <View style={styles.container}>
                    <View style={{ marginHorizontal: 10 }}>
                        <Text style={{ fontWeight: 'bold', marginTop: 10 }}>Issuing Party</Text>
                        <Text style={{ fontSize: 24, marginTop: 5 }}>{ nda.issuingUser.entityName }</Text>
                        <Text style={{ fontWeight: 'bold', marginTop: 10 }}>Receiving Party</Text>
                        <Text style={{ fontSize: 24, marginTop: 5 }}>{ nda.receivingEntityName }</Text>
                        <Text style={{ fontWeight: 'bold', marginTop: 10 }}>Created At</Text>
                        <Text style={{ fontSize: 24, marginTop: 5 }}>{ new Date(nda.createdAt).toUTCString() }</Text>

                        <Text style={{ fontWeight: 'bold', marginTop: 10 }}>Status</Text>
                        <Text style={{ fontSize: 18, marginTop: 5 }}>Waiting for signature from {nda.receivingEntityName }</Text>
                    </View>
                    

                    <Text style={{ marginTop: 20, marginBottom: 5, textAlign: 'center' }}>The contract is shown below</Text>
                    <View style={{ borderColor: '#888', borderWidth: 0.5}}>

                        <ScrollView style={{ flex: 1, height: 350 }}>
                            <View style={{ flex: 1, height: 350 }}>
                                <WebView
                                    style={{flex: 1 }}
                                    source={{
                                        uri: `${nda.ndaFiles[0].fileName}`,
                                    }}
                                    javaScriptEnabled={true}
                                    mixedContentMode='always'
                                    originWhitelist={['*']}
                                    allowFileAccess={true}
                                    allowFileAccessFromFileURLs={true}
                                    allowUniversalAccessFromFileURLs={true}
                                    onLoadStart={(syntheticEvent) => {
                                        const { nativeEvent } = syntheticEvent;
                                        console.log("WebView LOAD START", nativeEvent);
                                    }}
                                    onError={(syntheticEvent) => {
                                        const { nativeEvent } = syntheticEvent;
                                        console.warn("WebView error: ", nativeEvent);
                                    }}
                                    renderLoading={()=>(<ActivityIndicator size='large' color={'red'} />)}
                                />
                            </View>
                        </ScrollView>

                    </View>
                    <Button title='Download Contract' onPress={downloadNda} />

                </View>
            )}
            
            
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
    },
    pdf: {
        flex: 1,
        alignSelf: 'stretch',
    }
});