import React, { useEffect, useRef, useState } from "react";
import SignatureScreen, { SignatureViewRef } from "react-native-signature-canvas";
import { Button, StyleSheet, Text, View } from "react-native";

interface Props {
    text: string;
    onOK: (signature: string) => void;
    setScroll: (scrollEnabled: boolean) => void;
    signature: string|null;
}

const Sign: React.FC<Props> = ({ text, onOK, setScroll, signature }) => {
  const ref = useRef<SignatureViewRef>(null);
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    // Allow the parent to reset the signature
    if (signature === null) {
      ref.current?.clearSignature();
      setConfirmed(false);
    }
  }, [signature]);

  const handleSignature = (signature: string) => {
    console.log(signature);
    onOK(signature);
  };

  const handleClear = () => {
    setConfirmed(false);
    ref.current?.clearSignature();
  }

  const handleConfirm = () => {
    console.log("end");
    setConfirmed(true);
    ref.current?.readSignature();
  }

  const undo = () => {
    ref.current?.undo();
  };

  const erase = () => {
    ref.current?.erase();
  };

  const draw = () => {
    ref.current?.draw();
  };

  const changeColor = (color: string) => {
    ref.current?.changePenColor(color);
  };

  return (
    <View style={styles.container}>
      <View style={styles.box}>
        <SignatureScreen
            ref={ref}
            onOK={handleSignature}
            onBegin={() => setScroll(false)}
            onEnd={() => setScroll(true)}
            style={styles.sign}
            webviewContainerStyle={styles.sign}
            webStyle={`
                .m-signature-pad {
                  background-color: transparent;
                }
                .m-signature-pad {
                  flex: 1;
                  box-shadow: none;
                  border-radius: 10px;
                }
                .m-signature-pad--footer {
                  display: none;
                }
                `}
            backgroundColor={'rgba(0,0,0,0)'}
            // dataURL={url}
        />
      </View>

      {/* <View style={styles.row}>
        <Button title="undo" onPress={undo} />
        <Button title="draw" onPress={draw} />
        <Button title="erase" onPress={erase} />
        <Button title="change color" onPress={()=>changeColor("#d45")} />
      </View> */}
      <View style={styles.row}>
        <Button
            title="Clear"
            onPress={handleClear}
        />
        { !confirmed && (<Button
            title="Confirm"
            onPress={handleConfirm}
        />) }
        { confirmed && (<Text style={{ fontSize: 18, color: 'green', marginRight: 5 }}>Confirmed</Text>)}
        
      </View>
      
      { !confirmed && (<Text style={{ textAlign: 'center', marginTop: 15, marginBottom: 15 }}>Confirm your signature to send the NDA!</Text>) }      

    </View>
  );
};

export default Sign;

const styles = StyleSheet.create({
    container: {
      flex: 1,
      // alignItems: 'center',
      // justifyContent: 'center',
      padding: 10,
      backgroundColor: 'white',
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      height: 50,
      backgroundColor: '#eee'
    },
    box: {
      // backgroundColor: 'green',
      height: 150,
      borderColor: 'green'
    },
    sign: {
      backgroundColor: 'transparent'
    }
  });