import 'react-native-gesture-handler'; // This MUST be first


import { NavigationContainer, NavigationHelpers, ParamListBase, useNavigation, useNavigationContainerRef } from '@react-navigation/native';
import { StatusBar } from 'expo-status-bar';
import { Image, Platform, ScrollView, StyleSheet, Text, View } from 'react-native';
import BottomNavigation from './BottomNavigation';
import { useFonts } from 'expo-font';
import { createStackNavigator } from '@react-navigation/stack';
import Homepage from './components/Homepage';
import Login from './components/guest/Login';
import CreateAccount from './components/guest/CreateAccount';
import * as Linking from 'expo-linking';
import GuestSign from './components/nda/GuestSign';
import GuestView from './components/nda/GuestView';
import GuestThankYou from './components/nda/GuestThankYou';
import {AlertNotificationRoot} from 'react-native-alert-notification';

import {TailwindProvider} from 'tailwind-rn';
import utilities from './tailwind.json';
import ForgotPassword from './components/guest/ForgotPassword';
import HomepageWeb from './components/Homepage.web';
import WebResetPassword from './components/web/WebResetPassword';
import WebResetPasswordSuccess from './components/web/WebResetPasswordSuccess';
import { useAssets } from 'expo-asset';
import { LogoHeader } from './LogoHeader';

export type RootStackParamList = {
  NDADetails: { id: number };
};

const Stack = createStackNavigator();

type Props = {
  navigation: NavigationHelpers<ParamListBase>;
}

export default function App({ navigation }: Props) {

  const [iconsLoaded] = useFonts({
    'Ionicons': require('./assets/Ionicons.ttf')
  });
  const [assets, error] = useAssets([require('./assets/Logo.png')]);


  if (!iconsLoaded) {
    return null;
  }

  return (
    <TailwindProvider utilities={utilities}>
      <NavigationContainer>
        <Stack.Navigator
          screenOptions={{
            headerShown: true,
          }}
          initialRouteName="home"
          >
            
            <Stack.Screen name="home" component={Homepage} options={{ title: 'SimpleNDA', headerTitle: (props) => <LogoHeader {...props} /> }} />
            <Stack.Screen name="login" component={Login} options={{ title: 'Account Login', headerTitle: (props) => <LogoHeader {...props} /> }} />
            <Stack.Screen name="create-account" component={CreateAccount} options={{ title: 'Create Account', headerTitle: (props) => <LogoHeader {...props} /> }} />
            <Stack.Screen name="forgot-password" component={ForgotPassword} options={{ title: 'Forgot Password', headerTitle: (props) => <LogoHeader {...props} /> }} />
            <Stack.Screen name="reset-password" component={WebResetPassword} options={{ title: 'Reset Password', headerLeft: () => null, headerTitle: (props) => <LogoHeader {...props} /> }} />
            <Stack.Screen name="reset-password-success" component={WebResetPasswordSuccess} options={{ title: 'Password Updated', headerLeft: () => null, headerTitle: (props) => <LogoHeader {...props} /> }} />
            
            <Stack.Screen name="guest-sign" component={GuestSign} options={{ title: 'SimpleNDA: Sign NDA', headerLeft: () => null }} />
            <Stack.Screen name="guest-view" component={GuestView} options={{ title: 'SimpleNDA: NDA Complete', headerLeft: () => null }} />
            <Stack.Screen name="guest-thankyou" component={GuestThankYou} options={{ title: 'SimpleNDA: Account Created', headerLeft: () => null }} />
            
            <Stack.Screen name="logged-in" component={BottomNavigation} options={{ headerShown: false, headerTitle: (props) => <LogoHeader {...props} /> }} />
        </Stack.Navigator>
      </NavigationContainer>
    </TailwindProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
