import React, { createRef, useRef, useState } from 'react';
import { Button, Image, ScrollView, StyleSheet, Text, TextInput, View } from 'react-native';
import { Link, NavigationHelpers, ParamListBase, RouteProp } from '@react-navigation/native';
import ServerApi from '../../lib/server-api';
import { NDADetailsApi } from '../../NDADetails';
import SignaturePad from 'react-signature-pad-wrapper';
import { HorizontalRule } from '../helper/HorizontalRule';

type Props = {
    navigation: NavigationHelpers<ParamListBase>;
    route: RouteProp<ParamListBase>;
}

export default function GuestSign({ navigation, route }: Props) {
    const [nda, setNda] = useState<NDADetailsApi|null>(null);
    const [showError, setShowError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const canvas = createRef<SignaturePad>();

    const { id, token } = route.params as any; //:(
    console.log('GUEST SIGN, GOT ID + TOKEN: ', id, token);
    const loadNda = async () => {
        if (isLoaded) return;
        const nda = await ServerApi.Guest.retrieveNda(id, token);
        setIsLoaded(true);
        console.log('Loaded NDA: ', nda);
        setNda(nda);
    };

    loadNda();

    const submit = async () => {
        setShowError(false);

        const signature = canvas.current?.toDataURL();
        console.log('Got signature');
        console.log(signature);

        const payload = {
            id,
            token,
            signature,
        };

        try {
            const response = await ServerApi.Guest.signNda(payload);
            if (response.error) {
                throw new Error(response.errorMessage);
            }
            console.log('Got response:', JSON.stringify(response));
            navigation.navigate('guest-view', { id, token });
        } catch (error) {
            console.error('Got an error: ', JSON.stringify(error));
            setShowError(true);
        }
        
    };

    const clearCanvas = () => {
        canvas.current?.clear();
    };

    return (
        <ScrollView style={styles.container}>
            { nda && (<View style={styles.container}>
                <View style={{ marginHorizontal: 10 }}>
                    <Text style={{ fontWeight: 'bold', marginTop: 10 }}>Issuing Party</Text>
                    <Text style={{ fontSize: 20, marginTop: 5 }}>{ nda.issuingUser.entityName }</Text>
                    <Text style={{ fontWeight: 'bold', marginTop: 10 }}>Receiving Party</Text>
                    <Text style={{ fontSize: 20, marginTop: 5 }}>{ nda.receivingEntityName }</Text>

                    <Text style={{ fontWeight: 'bold', marginTop: 10 }}>Status</Text>
                    <Text style={{ fontSize: 20, marginTop: 5 }}>
                        { nda.status == 0 && `Waiting for signature from ${nda.receivingEntityName}`}
                        { +nda.status == 1 && `Contract has been signed by both parties`}
                    </Text>

                    <Text style={{ textAlign: 'center', fontSize: 16, marginTop: 15, marginBottom: 10 }}>View the contract below</Text>
                </View>
                <iframe src={`${nda.ndaFiles[0].fileName}#toolbar=1`} height={400} width='100%' style={{ margin: '0 auto' }} />
                
                { nda.status == 0 && (
                    <View>
                        <HorizontalRule text='To complete the NDA' />
                        {/* <Text style={{ fontSize: 20, marginVertical: 15 }}>To complete the NDA</Text> */}
                        <Text style={{ fontSize: 16, marginBottom: 5 }}>Add your signature to the area below</Text>
                        <View style={{ width: 450, height: 180, borderWidth: 1, borderColor: '#333' }}>
                            <SignaturePad ref={canvas} height={300} width={450} redrawOnResize />
                        </View>
                        <View style={{ width: 450, marginBottom: 15 }}>
                            <Button title='Clear' onPress={clearCanvas} />
                            {/* <Button title='Confirm' /> */}
                        </View>
                        <Button title='Complete NDA' onPress={submit}></Button>
                    </View>
                )}

                { +nda.status == 1 && (
                    <View>
                        <Button title="Download NDA" onPress={() => navigation.navigate('guest-view', { id, token })} />
                    </View>
                )}
                
            </View>)}
            

            { showError && <Text style={{fontSize: 20, color: '#F00' }}>Link is invalid or expired</Text> }

        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        paddingLeft: 5,
        paddingRight: 5,
    },
});