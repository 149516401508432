import { NavigationHelpers, ParamListBase } from '@react-navigation/native';
import { useState } from 'react';
import { Button, Image, Pressable, ScrollView, StyleSheet, Text, TextInput, View } from 'react-native';
import { useTailwind } from 'tailwind-rn/dist';
import ServerApi from '../../lib/server-api';

type Props = {
    navigation: NavigationHelpers<ParamListBase>;
}
export default function CreateAccount({ navigation }: Props) {
    const tailwind = useTailwind();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const submit = async () => {
        const accountData = {
            email,
            password,
        };

        setHasError(false);

        if (!email || !email.length || email.indexOf('@') === -1 || email.indexOf('.') === -1) {
            setErrorMessage('Invalid email address');
            setHasError(true);
            return;
        }

        if (password !== confirmPassword) {
            setErrorMessage('Your password confirmation does not match');
            setHasError(true);
            return console.error('Your password confirmation does not match');
        }

        try {
            const response = await ServerApi.Guest.register(accountData);
            console.log('Got response:', response);
            if (response && response.error) {
                setErrorMessage(response.errorMessage);
                setHasError(true);
                return;
            }

            navigation.navigate('login', { createdEmail: response.email });
        } catch (error) {
            console.error('Got an error: ', error);
        }
    };

    return (
        <ScrollView style={styles.container}>
            <Text style={tailwind('text-3xl font-semibold mt-5')}>Create an account</Text>
            <Text style={tailwind('text-lg mb-10 mt-2')}>Setup a new account with your e-mail address and password.</Text>

            <Text style={tailwind('text-lg font-medium')}>Email</Text>
            <TextInput 
                style={styles.styledInput} 
                placeholder='Enter your email address'
                autoComplete='email'
                keyboardType='email-address'
                onChangeText={newText => setEmail(newText)} 
                defaultValue={email}
                autoFocus={true}
                />

            <Text style={tailwind('text-lg font-medium mt-5')}>Password</Text>
            <TextInput 
                style={styles.styledInput} 
                autoComplete="password-new"
                placeholder='Enter your password' 
                onChangeText={newText => setPassword(newText)} 
                defaultValue={password} 
                />

            <Text style={tailwind('text-lg font-medium mt-5')}>Confirm Password</Text>
            <TextInput 
                style={styles.styledInput} 
                keyboardType="default"
                placeholder='Enter your password'
                onChangeText={newText => setConfirmPassword(newText)} 
                defaultValue={confirmPassword} 
                />

            { hasError ? <Text style={tailwind('text-base text-red-600 my-5')}>{ errorMessage }</Text> : null }
            
            <Pressable style={tailwind('bg-cyan-700 text-white rounded-full w-full mt-20')} onPress={submit}>
                <Text style={tailwind('text-center text-white font-semibold text-lg my-3')}>Create Account</Text>
            </Pressable>
            {/* <Button title='Create New Account' onPress={submit}></Button> */}
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        paddingLeft: 15,
        paddingRight: 15,
    },
    styledInput: {
        borderBottomColor: '#111',
        borderBottomWidth: 1,
        height: 40,
        fontSize: 18,
    }
});