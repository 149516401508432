import { StyleSheet } from "react-native/types";

export const themeStyles = {
    container: {
        backgroundColor: '#fff',
        paddingLeft: 15,
        paddingRight: 15,
    },
    themeInput: {
        borderBottomColor: '#111',
        borderBottomWidth: 1,
        height: 40,
        fontSize: 18,
    },
};