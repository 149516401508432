import { useState } from 'react';
import { Button, Pressable, ScrollView, StyleSheet, Text, TextInput, View } from 'react-native';
import ServerApi from '../../lib/server-api';
import { useTailwind } from 'tailwind-rn/dist';
import { NavProps, RouteProps, UIError } from '../../types/ui-types';

type RouteParams = {
    id: string;
    token: string;
}

export default function WebResetPassword({ navigation, route }: NavProps & RouteProps) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [tokenError, setTokenError] = useState<UIError>({ error: false, message: '' });
    const [formError, setFormError] = useState<UIError>({ error: false, message: '' });
    const tailwind = useTailwind();

    const { id, token } = route.params as RouteParams;
    
    console.log('Reset Password, GOT ID + TOKEN: ', id, token);
    const verifyToken = async () => {
        if (isLoaded) return;
        const response = await ServerApi.Guest.getResetPassword(id, token);
        setIsLoaded(true);
        console.log('Loaded token: ', response);
        if (response.error) {
            return setTokenError({ error: true, message: 'Invalid reset token' });
        }
    };

    verifyToken();

    const submit = async () => {
        setFormError({ error: false, message: '' });

        if (password !== confirmPassword) {
            return setFormError({ error: true, message: 'Passwords do not match' });
        }

        const payload = {
            password,
            userId: id,
            token,
        };

        try {
            const response = await ServerApi.Guest.resetPassword(payload);
            if (response.error) {
                return setFormError({ error: true, message: response.errorMessage });
            }
            console.log('Got response:', response);
            // Show view to go to app
            navigation.navigate('reset-password-success');
        } catch (error) {
            console.error(error);
            setFormError({ error: true, message: 'An error occured, please try again' });
        }
        
    };

    return (
        <ScrollView style={styles.container}>

            { tokenError.error ? (
                <View style={styles.container}>
                    <View style={tailwind('bg-red-400 border-red-500 p-3 border-2 rounded-md my-3')}>
                        <Text>{tokenError.message}</Text>
                    </View>
                </View>
            ) : (
                <View style={styles.container}>
                    <Text style={tailwind('text-3xl font-semibold my-5')}>Create new password</Text>
                    <Text style={tailwind('text-lg mb-10 mt-2')}>Enter a new password below, and save it somewhere safe!</Text>

                    <Text style={tailwind('text-lg font-medium')}>New Password</Text>
                    <TextInput 
                        style={styles.styledInput} 
                        autoComplete="password-new"
                        placeholder='Enter your password' 
                        onChangeText={newText => setPassword(newText)} 
                        defaultValue={password} 
                        />

                    <Text style={tailwind('text-lg font-medium mt-5')}>Confirm Password</Text>
                    <TextInput 
                        style={styles.styledInput} 
                        keyboardType="default"
                        placeholder='Enter your password'
                        onChangeText={newText => setConfirmPassword(newText)} 
                        defaultValue={confirmPassword} 
                        />

                    { formError.error ? <Text style={tailwind('text-base text-red-600 my-5')}>{ formError.message }</Text> : null }

                    <Pressable style={{ ...styles.dropShadow, ...tailwind('bg-cyan-700 text-white rounded-full w-full mt-20')}} onPress={submit}>
                        <Text style={tailwind('text-center text-white font-semibold text-lg my-3')}>Update Password</Text>
                    </Pressable>
                    {/* <Button title='Update Password' onPress={submit}></Button> */}
                    

                </View>
            )}
            


        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        paddingLeft: 5,
        paddingRight: 5,
    },
    styledInput: {
        borderBottomColor: '#111',
        borderBottomWidth: 1,
        height: 40,
        fontSize: 18,
    },
    dropShadow: {
        shadowColor: '#000',
        shadowOpacity: 0.5,
        elevation: 2,
        shadowOffset: { width: 0, height: 3 },
    },
});