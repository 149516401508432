
export const apiBase = __DEV__ ? 'https://api.simplemnda.com' : 'https://api.simplemnda.com';
// export const apiBase = __DEV__ ? 'http://localhost:3005' : 'https://api.simplemnda.com';
export const apiDocumentBase = `${apiBase}/temp-store/`;

const fetchPost = (endpoint: string, data: any) => {
    return fetch(`${apiBase}/${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include',
        }).then(r => r.json());
}

const fetchGet = (endpoint: string) => {
    return fetch(`${apiBase}/${endpoint}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    }).then(r => r.json());
}

const ServerApi = {
    login: (data: any) => fetchPost('login', data),
    logout: () => fetchPost('logout', {}),

    User: {
        user: () => fetchGet('user'),
        changePassword: (data: any) => fetchPost('user/change-password', data),
        updateProfile: (data: any) => fetchPost('user/update-profile', data),
    },

    Guest: {
        register: (data: any) => fetchPost('user', data),
        createAccount: (data: any) => fetchPost('guest/create-account', data),
        forgotPassword: (data: any) => fetchPost('guest/forgot-password', data),
        getResetPassword: (id: string, token: string) => fetchGet(`guest/forgot-password/${id}/${token}`),
        resetPassword: (data: any) => fetchPost(`guest/reset-password/`, data),
        retrieveNda: (id: string, token: string) => fetchGet(`guest/retrieve-nda/${id}/${token}`),
        signNda: (data: any) => fetchPost('guest/sign-nda', data),
    },

    NDA: {
        myNdas: () => fetchGet('ndas'),
        details: (id: string|number) => fetchGet(`ndas/${id}`),
        create: (data: any) => fetchPost(`create-nda`, data),
        archive: (id: string|number, archive: boolean) => fetchPost(`nda/${id}/archive`, { archive }),
    },

    SSO: {
        sendGoogle: (data: any) => fetchPost('sso/google', data),
        sendApple: (data: any) => fetchPost('sso/apple', data),
    }

};

export default ServerApi;